const SESSION_KEY = 'session_id';

export function getSessionId(): string {
  let sessionId = sessionStorage.getItem(SESSION_KEY);

  if (sessionId === null) {
    sessionId = new Date().getTime().toString();
    sessionStorage.setItem(SESSION_KEY, sessionId);
  }

  return sessionId;
}
