import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { TranslationsQuery } from '../../../types/api';
import { TRANSLATIONS } from '../../queries';
import { getLocale } from '../locale';

export enum Module {
  ADMIN = 'admin',
  BOOKING = 'booking',
  CONFIRM = 'confirm',
  LOGIN = 'login',
  NOT_FOUND = 'not_found',
  SELF_SERVICE = 'selfservicing',
}

export default function useUpdateTranslations(moduleId?: Module) {
  const language = getLocale();

  const { i18n } = useTranslation();

  const { data: translationsData, loading: translationsLoading } =
    useQuery<TranslationsQuery>(TRANSLATIONS, {
      variables: { language, moduleId: moduleId ?? 'translation' },
    });

  const translations = useMemo(
    () =>
      JSON.parse(translationsData?.staticTranslations?.translations ?? '{}'),
    [translationsData],
  );

  useEffect(() => {
    if (translationsLoading) {
      return;
    }
    if (language !== null) {
      i18n.addResources(language, moduleId ?? 'translation', translations);
      i18n.changeLanguage(language);
    }
  }, [translations, translationsLoading]);
}
