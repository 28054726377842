import React from 'react';

type Props = {
  children?: React.ReactNode | string;
};

export const Heading1 = ({ children }: Props) => (
  <h1 className="font-27sans text-4xl font-light">{children}</h1>
);

export const Heading2 = ({ children }: Props) => (
  <h2 className="font-27sans text-3xl font-light">{children}</h2>
);

export const Heading3 = ({ children }: Props) => (
  <h3 className="font-27sans text-2xl font-light">{children}</h3>
);

export const Heading4 = ({ children }: Props) => (
  <h4 className="font-27sans text-xl font-light">{children}</h4>
);

export const Heading5 = ({ children }: Props) => (
  <h5 className="font-27sans text-lg font-light">{children}</h5>
);

export const Text = ({ children }: Props) => (
  <p className="font-normal">{children}</p>
);

export const FormSectionTitle = ({ children }: Props) => (
  <p className="font-27sans text-2xl font-light">{children}</p>
);

export const InfoText = ({ children }: Props) => (
  <p className="px-2 text-gray-500 font-normal">{children}</p>
);

export const SmallText = ({ children }: Props) => (
  <p className="text-sm">{children}</p>
);
