import React, { useMemo } from 'react';
import styled from 'styled-components';

interface SizeProps {
  size: number;
  fontSize: number;
}

const AvatarContainer = styled.div<SizeProps>`
  width: ${({ size }) => `${size}rem`};
  height: ${({ size }) => `${size}rem`};
  border-radius: 0.25rem;
  overflow: hidden;
`;

const Initials = styled.div<SizeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.GREY_4};
  font-size: ${({ fontSize }) => `${fontSize}rem`};
  color: #262626;
`;

const Image = styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.GREY_4};
`;

interface Props {
  src?: string | null;
  firstName: string;
  lastName: string;
  size?: number;
  fontSize?: number;
  onClick?: () => void;
}

export default function Avatar({
  firstName,
  fontSize = 1,
  lastName,
  onClick,
  size = 2,
  src,
}: Props) {
  const initials = useMemo(() => {
    if (!firstName || !lastName) {
      return '';
    }

    return `${firstName.charAt(0).toUpperCase()}${lastName
      .charAt(0)
      .toUpperCase()}`;
  }, [firstName, lastName]);

  return (
    <AvatarContainer
      className="__avatarContainer"
      fontSize={fontSize}
      onClick={onClick}
      size={size}
    >
      {src ? (
        <Image alt={`${firstName} ${lastName}'s avatar image.`} src={src} />
      ) : (
        <Initials fontSize={fontSize} size={size}>
          {initials}
        </Initials>
      )}
    </AvatarContainer>
  );
}
