import React, { PropsWithChildren } from 'react';

import { InputFieldProps } from '../_common/types';
import ModalField from '../ModalField';

import AddressModal from './AddressModal';

function AddressField(props: PropsWithChildren<InputFieldProps<string>>) {
  return (
    <ModalField
      {...props}
      renderModal={(toggleModalOpen, onChange, value) => (
        <AddressModal
          address={value as string}
          onClose={toggleModalOpen}
          onSave={onChange}
          required={props.required}
        />
      )}
    />
  );
}

AddressField.displayName = 'AddressField';

export default AddressField;
