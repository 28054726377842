export function removeTypename(object?: Object): Object | undefined {
  for (const prop in object) {
    if (typeof object[prop] === 'object') {
      removeTypename(object[prop]);
    }

    if (prop === '__typename') {
      delete object[prop];
    }
  }

  return object;
}
