import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Observable,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import axios from 'axios';

import 'whatwg-fetch';

import { getLocale } from '../../_common/utils/locale';
import {
  clearToken,
  getDecodedToken,
  getToken,
  setToken,
  shouldRefreshToken,
} from '../../_common/utils/token';

import { getCacheKey } from './cacheKey';

interface LinkError {
  graphQLErrors?: ReadonlyArray<any> | undefined;
  networkError?: Error | undefined;
  operation: any;
  forward: (operation: any) => any;
}

// Can be used to add a token to the request headers.
const request = async (operation: {
  setContext: (options: object) => void;
}) => {
  const token = getToken();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : undefined,
      'content-language': getLocale(),
    },
  });
};

const cache = new InMemoryCache({
  dataIdFromObject: getCacheKey,
});

const refreshLink = new TokenRefreshLink({
  isTokenValidOrUndefined: () => !shouldRefreshToken(getDecodedToken()),
  fetchAccessToken: () => {
    const token = getToken();

    return axios.post(
      `${process.env.API_HOST}:${process.env.API_PORT}${process.env.API_PATH}/refresh`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  handleResponse: (_operation, accessTokenField) => (response: any) => {
    return {
      data: {
        [accessTokenField]: response.data,
      },
    };
  },
  handleFetch: (accessToken: string) => {
    setToken(accessToken);
  },
  handleError: () => {
    clearToken();
    window.location.replace(`/${window.location.search}`);
  },
}) as any;

// The default requestLink.
const requestLink = new ApolloLink(
  (operation: any, forward: (operation: any) => any) =>
    new Observable((observer) => {
      let handle: any;
      // The reason for using Promise.resolve here is because the typing for using
      // async observer are invalid.
      Promise.resolve(operation)
        .then((oper: any) => request(oper))
        .then(() => {
          // This handle is what your Query uses to observe handled values.
          // Complete --> completed request,
          // next is a request for data an
          // error implies an errored request.
          handle = forward(operation).subscribe({
            complete: observer.complete.bind(observer),
            error: observer.error.bind(observer),
            next: observer.next.bind(observer),
          });
        })
        .catch(() => observer.error.bind(observer));

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    }),
);

// I opted to change this to apollo-client so we can hook into our requests etc.
// This will improve the developer experience by a lot in my opinion.
// They will never have to migrate away from boost in case the features are lacking,
// and this config provides the exact same features. It can be expanded when it needs to be.
const apolloClient = new ApolloClient({
  cache,
  link: ApolloLink.from([
    refreshLink,
    onError(({ graphQLErrors, networkError }: LinkError) => {
      if (graphQLErrors) {
        graphQLErrors.map(
          ({
            extensions: { code },
            message,
          }: {
            message: string;
            extensions: {
              code: string;
            };
          }) => {
            if (code === 'UNAUTHENTICATED') {
              clearToken();
            }
            console.error(`[GraphQL error]: Message: ${message}.`);
          },
        );
      }
      if (networkError) {
        console.log(networkError);
      }
    }),
    requestLink,
    new HttpLink({
      credentials: 'same-origin',
      fetch: (uri, options) => {
        // @ts-ignore
        const { operationName } = JSON.parse(options?.body ?? '{}');

        return fetch(
          `${process.env.API_HOST}:${process.env.API_PORT}${process.env.API_PATH}/graphql?q=${operationName}`,
          options,
        );
      },
    }),
  ]),
});

export default apolloClient;
