import styled from 'styled-components';

import Cross from './cross';

const MODAL_PADDING = '2.5rem';

export const Footer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

export const Wrapper = styled.div`
  box-shadow: 0em 1px 2px 0px rgba(0, 0, 0, 0.25);
  padding: ${MODAL_PADDING};
  max-height: 98vh;
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  -ms-overflow-style: none;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CloseIcon = styled(Cross)`
  position: absolute;
  top: calc(${MODAL_PADDING} / 1.5);
  right: ${MODAL_PADDING};
  cursor: pointer;
`;

export const Title = styled.h1`
  display: inline;
  font-size: 1.8rem;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SubTitle = styled.h2`
  display: block;
  font-size: 1rem;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.GREY_3};
`;

export const Body = styled.div`
  background-color: white;
  overflow: hidden;
  width: 100%;
`;
