import React, {
  Context,
  Dispatch,
  PropsWithChildren,
  Reducer,
  ReducerState,
  useReducer,
} from 'react';

interface ProviderProps<R, S> {
  reducer: R;
  initialState: S;
  context: Context<[any, Dispatch<any>]>;
}

// TODO: make more opinionated and expose hook
export default function ContextProvider<
  R extends Reducer<any, any>,
  S extends ReducerState<R>,
>({
  children,
  context,
  initialState,
  reducer,
}: PropsWithChildren<ProviderProps<R, S>>) {
  const contextReducer = useReducer(reducer, initialState);

  if (context == null) {
    return null;
  }

  return <context.Provider value={contextReducer}>{children}</context.Provider>;
}
