import React, { useCallback } from 'react';
import styled from 'styled-components';

import { FieldsListQuery_category_fields_options as Option } from '../../types/api';

import { InputFieldProps } from './_common/types';
import RadioButton from './RadioButton';

const Wrapper = styled.div<{ indent?: boolean }>`
  margin-left: ${({ indent }) => (indent ? '1rem' : 0)};
`;

interface Props extends InputFieldProps<string | boolean> {
  options: Option[];
}

export default function RadioButtonsField({
  className,
  indent,
  name,
  onChange,
  options,
  value: selectedValue,
}: Props) {
  const handleOptionSelect = useCallback(
    (value) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <Wrapper>
      {options.map(({ label, value }, key) => (
        <RadioButton
          aria-describedby={`${name}-error`}
          className="mt-2"
          key={label}
          label={label}
          name={name}
          onChange={() => handleOptionSelect(value)}
          value={value === selectedValue}
        />
      ))}
    </Wrapper>
  );
}
