import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PrimaryButton, { SecondaryButton } from '../../button';
import Modal from '../../modal';

interface State {
  isOpen: boolean;
  message?: string;
}

const DEFAULT_STATE = {
  isOpen: false,
  message: undefined,
};

export function useConfirm() {
  const [{ isOpen, message }, setState] = useState<State>(DEFAULT_STATE);
  const { t } = useTranslation();

  const awaitingPromiseRef = useRef<{
    resolve: (value: boolean) => void;
  }>();

  async function confirm(message: string) {
    setState({ message, isOpen: true });

    return new Promise<boolean>((resolve) => {
      awaitingPromiseRef.current = { resolve };
    });
  }

  function handleCancel() {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(false);
    }

    setState(DEFAULT_STATE);
  }

  function handleConfirm() {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(true);
    }

    setState(DEFAULT_STATE);
  }

  function ConfirmModal() {
    return (
      <Modal
        isOpen={isOpen}
        onClose={handleCancel}
        title=""
        fitToContent
        shouldHideClose
      >
        <div>{message}</div>
        <div className="flex mt-10 space-x-10">
          <PrimaryButton onClick={handleConfirm} type="button">
            {t('button.confirm')}
          </PrimaryButton>
          <SecondaryButton onClick={handleCancel} type="button">
            {t('button.cancel')}
          </SecondaryButton>
        </div>
      </Modal>
    );
  }

  return { ConfirmModal, confirm };
}
