import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CategoriesQuery_categories as Category } from '../../types/api';
import PrimaryButton from '../button';
import { Heading1 } from '../Typography';

interface Props {
  categories: Category[];
  onSelect: (category: Category) => void;
}

const LargePrimaryButton = styled(PrimaryButton)`
  font-size: 1.25rem;
  padding: 1rem;
  width: 100%;
`;

export function CategoryContainer(props: Props) {
  const { categories, onSelect } = props;
  const { t } = useTranslation();

  return (
    <div className="w-full h-full px-3 py-4 overflow-y-auto">
      <div>
        <Heading1>
          <div
            dangerouslySetInnerHTML={{
              __html: t('booking:category_select.title', {
                instanceProvider: process.env.PROVIDER ?? 'Tryg',
              }),
            }}
          />
        </Heading1>
        <p className="mt-5">{t('booking:category_select.description')}</p>
      </div>
      <div className="flex flex-wrap mt-8 -mx-4 md:max-w-2xl">
        {categories.map((category) => (
          <div
            className={`w-full px-4 py-3 ${
              categories.length > 6 ? 'sm:w-1/2' : 'md:max-w-md'
            }`}
            key={category.key}
          >
            <LargePrimaryButton onClick={() => onSelect(category)}>
              {category.label}
            </LargePrimaryButton>
          </div>
        ))}
      </div>
    </div>
  );
}
