import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useQuery } from '@apollo/client';
import Color from 'color';

import { BrandInformationQuery } from '../../types/api';
import {
  BrandColors,
  DEFAULT_BRAND_COLOR,
  generateTailwindClasses,
} from '../../utils/tailwind';
import { BRAND_INFORMATION } from '../queries';

import { useBookingContext } from './BookingContext';

interface IBrandingContext {
  colors: BrandColors;
}

const defaultBranding = {
  colors: generateColors(DEFAULT_BRAND_COLOR),
};

function generateColors(brand: string): BrandColors {
  const color = Color(brand);

  return {
    200: color.lighten(0.6).hex(),
    300: color.lighten(0.2).hex(),
    400: brand,
    500: color.darken(0.2).hex(),
    600: color.darken(0.4).hex(),
  };
}

const BrandingContext = createContext<IBrandingContext>(defaultBranding);

export function BrandingProvider(props: PropsWithChildren<{}>) {
  const { children } = props;
  const styleRef = useRef(document.createElement('style'));
  const [{ categoryId }] = useBookingContext();

  // TODO: what about loading?
  const { data } = useQuery<BrandInformationQuery>(BRAND_INFORMATION, {
    variables: { categoryId },
  });

  const branding = useMemo(() => {
    const brandColor = data?.brandInformation?.brandColor ?? '';

    if (brandColor !== '') {
      return {
        colors: generateColors(brandColor),
      };
    }

    return defaultBranding;
  }, [data?.brandInformation]);

  useEffect(() => {
    document.head.appendChild(styleRef.current);

    return () => {
      document.head.removeChild(styleRef.current);
    };
  }, []);

  useEffect(() => {
    if (styleRef.current !== undefined) {
      styleRef.current.innerHTML = generateTailwindClasses(branding.colors);
    }
  }, [branding]);

  return (
    <BrandingContext.Provider value={branding}>
      {children}
    </BrandingContext.Provider>
  );
}
