import { gql } from '@apollo/client';

export const FEATURE_FLAGS = gql`
  query FeatureFlagQuery($userControllable: Boolean) {
    featureFlags(userControllable: $userControllable) {
      id
      enabled
      key
      description
      label
      userControllable
      featureFlagSettings {
        id
        key
        description
        label
        required
        type
        value
      }
    }
  }
`;

export const FEATURE_FLAG_SETTING = gql`
  query FeatureFlagSetting($featureFlagSettingKey: String!) {
    featureFlagSetting(featureFlagSettingKey: $featureFlagSettingKey) {
      key
      value
    }
  }
`;
