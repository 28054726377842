import React, { ReactNode } from 'react';
import { FieldInputProps, FieldMetaState, useField } from 'react-final-form';
import cx from 'clsx';

import ErrorMessage from '../_common/errorMessage';
import Label from '../_common/label';
import InputHelperText from '../InputHelperText';

interface Props {
  children: (
    input: FieldInputProps<any, HTMLElement>,
    meta: FieldMetaState<any>,
  ) => ReactNode;
  label?: string;
  name: string;
  className?: string;
  infoMessage?: string;
  parse?: (value: any, name: string) => any;
  format?: (value: any, name: string) => any;
}

export default function InputField({
  children,
  className,
  format,
  infoMessage,
  label,
  name,
  parse,
}: Props) {
  const { input, meta } = useField(name, { format, parse });

  return (
    <div className={cx('w-full', className)}>
      <div className="flex justify-between w-full">
        {label && <Label htmlFor={name}>{label}</Label>}
        {infoMessage && <InputHelperText infoMessage={infoMessage} />}
      </div>
      {children(input, meta)}
      {meta.touched && meta.error !== undefined && (
        <ErrorMessage id={`${name}-error`}>{meta.error}</ErrorMessage>
      )}
    </div>
  );
}
