import React from 'react';
import cx from 'clsx';

export const ErrorMessage = ({
  children,
  className,
  id,
}: React.PropsWithChildren<React.HTMLAttributes<HTMLParagraphElement>>) => (
  <p className={cx('text-red-700 text-sm leading-tight', className)} id={id}>
    {children}
  </p>
);

export default ErrorMessage;
