import React, { useEffect, useRef } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { FieldInputProps } from 'react-final-form';
import { useButton } from '@react-aria/button';
import { useMenuTrigger } from '@react-aria/menu';
import { useMenuTriggerState } from '@react-stately/menu';
import { MenuTriggerProps } from '@react-types/menu';

import { Popover } from '../Popover';

interface Props extends FieldInputProps<string, HTMLElement>, MenuTriggerProps {
  tabIndex?: number;
}

function ColorField({
  name,
  onBlur,
  onChange,
  onFocus,
  value,
  ...props
}: Props) {
  const colorPreviewRef = useRef<HTMLButtonElement>(null);
  const colorContainerRef = useRef<HTMLDivElement>(null);
  // const [open, setOpen] = useState(false);

  let state = useMenuTriggerState(props);

  let { menuTriggerProps } = useMenuTrigger({}, state, colorPreviewRef);

  // Get props for the button based on the trigger props from useMenuTrigger
  let { buttonProps } = useButton(menuTriggerProps, colorPreviewRef);

  function handleChange(color: ColorResult) {
    const ref = colorPreviewRef && colorPreviewRef.current;

    if (ref != null) {
      ref.setAttribute('style', `background-color: ${color.hex}`);
    }
  }

  function handleChangeComplete(color: ColorResult) {
    handleChange(color);
    onChange(color.hex);
  }

  // EventListener for closing on outside click
  useEffect(() => {
    function handleClickOutsideColorPicker(event: MouseEvent) {
      event.preventDefault();

      const slotNode = colorContainerRef.current;
      if (slotNode && !slotNode.contains(event.target as Node)) {
        state.close();
      }
    }

    if (state.isOpen) {
      document.addEventListener('click', handleClickOutsideColorPicker);
    }

    return () => {
      document.removeEventListener('click', handleClickOutsideColorPicker);
    };
  }, [state]);

  useEffect(() => {
    const ref = colorPreviewRef && colorPreviewRef.current;

    if (ref != null) {
      ref.setAttribute('style', `background-color: ${value};`);
    }
  }, [colorPreviewRef, value]);

  return (
    <div className="relative" ref={colorContainerRef}>
      <button
        {...buttonProps}
        className="px-2 h-8 text-white border focus:border-black border-white rounded-sm"
        id={name}
        ref={colorPreviewRef}
        type="button"
      >
        {value}
      </button>
      {state.isOpen && (
        <Popover isOpen={state.isOpen} onClose={state.close}>
          <ChromePicker
            className="top-full absolute z-10"
            color={value}
            onChange={handleChange}
            onChangeComplete={handleChangeComplete}
            disableAlpha
          />
        </Popover>
      )}
    </div>
  );
}

export default ColorField;
