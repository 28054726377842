import * as React from 'react';
import { Normalize } from 'styled-normalize';

import ProjectStyles from './_projectStyles';

require('./fontAwesome');

const GlobalStyles = () => (
  <>
    <Normalize />
    <ProjectStyles />
  </>
);

export default GlobalStyles;
