import { ChangeEvent, MouseEvent, useState } from 'react';

export interface FormProps<T> {
  value?: T;
  setValue: (value: T) => void;
  onChange: (
    event?: ChangeEvent<HTMLInputElement | HTMLSelectElement | MouseEvent>,
  ) => void;
}

/**
 * Helper hook function for forms inputs with boolean values
 *
 * Calling onChange will set the current value to the opposite boolean value
 * @param initialValue Initial value for the input field, defaults to false
 */
export function useToggleInput(initialValue = false): FormProps<boolean> {
  const [value, setValue] = useState(initialValue);

  function toggleValue() {
    return setValue(!value);
  }

  return {
    value,
    setValue,
    onChange: toggleValue,
  };
}

/**
 * Helper hook function for form inputs
 * @param initialValue The initial value for the form input
 */
export default function (initialValue?: string): FormProps<string> {
  const [value, setValue] = useState(initialValue);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return {
    value,
    setValue,
    onChange: handleChange,
  };
}
