import { UserListQuery, UserListQuery_users } from '../../types/api';

export default function sortUsers(data: UserListQuery | undefined) {
  function compare(a: UserListQuery_users, b: UserListQuery_users) {
    const lastNameA = a.lastName.toLowerCase();
    const lastNameB = b.lastName.toLowerCase();

    if (lastNameA !== lastNameB) {
      return lastNameA.localeCompare(lastNameB);
    }

    const firstNameA = a.firstName.toLowerCase();
    const firstNameB = b.firstName.toLowerCase();

    if (firstNameA !== firstNameB) {
      return firstNameA.localeCompare(firstNameB);
    }

    const emailA = a.email.toLowerCase();
    const emailB = b.email.toLowerCase();

    return emailA.localeCompare(emailB);
  }

  return { users: [...(data?.users ?? [])].sort(compare) };
}
