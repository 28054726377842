import { defaultDataIdFromObject } from '@apollo/client';

const cacheKeyMap: Record<string, string | null> = {
  CategoryBrandInformationQuery: 'categoryId',
  Context: 'contextId',
};

export function getCacheKey(cacheObject: { __typename: string }) {
  return (
    cacheKeyMap[cacheObject.__typename] ?? defaultDataIdFromObject(cacheObject)
  );
}
