import React from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import styled from 'styled-components';

import Icon from '../icon/Icon';
const StyledBanner = styled.div`
  width: 100%;
  border: solid 1px ${({ theme }) => theme.colors.GREY_4};
  background-color: ${({ theme }) => theme.colors.GREY_6};
  color: solid 1px ${({ theme }) => theme.colors.GREY_1};
  padding: 10px;
  display: flex;
  flex-wrap: flex-start;
  border-radius: ${({ theme }) => theme.variables.BORDER_RADIUS};
  p {
    color: solid 1px ${({ theme }) => theme.colors.GREY_1};
    line-height: 24px;
  }
  .content {
    margin: 5px;
  }
  &.warning {
    border-color: ${({ theme }) => theme.colors.WARNING};
    background: ${({ theme }) => theme.colors.WARNING_LIGHT};
    color: ${({ theme }) => theme.colors.WARNING_DARK};
    &p {
      color: s ${({ theme }) => theme.colors.WARNING_DARK};
    }
  }
  &.success {
    border-color: ${({ theme }) => theme.colors.SUCCESS};
    background: ${({ theme }) => theme.colors.SUCCESS_LIGHT};
    color: ${({ theme }) => theme.colors.SUCCESS_DARK};
    p {
      color: ${({ theme }) => theme.colors.SUCCESS_DARK};
    }
  }
  &.error {
    border-color: ${({ theme }) => theme.colors.ERROR};
    background: ${({ theme }) => theme.colors.ERROR_LIGHT};
    color: ${({ theme }) => theme.colors.ERROR_DARK};
    p {
      color: ${({ theme }) => theme.colors.ERROR_DARK};
    }
  }
`;

interface Props {
  children: string;
  type: 'success' | 'warning' | 'error' | 'neutral';
  onClose?: () => void;
}

export default function Banner({ children, type }: Props) {
  const icon = {
    success: 'check-circle',
    warning: 'bell',
    error: 'exclamation-circle',
    neutral: 'info-circle',
  };

  return (
    <StyledBanner className={type}>
      <Icon icon={icon[type] as IconName} />
      <div className="content">
        <p>{children}</p>
      </div>
    </StyledBanner>
  );
}
