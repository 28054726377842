import React from 'react';
import styled, { css } from 'styled-components';

export enum SpinnerSizes {
  SMALL = 'SMALL',
  REGULAR = 'REGULAR',
  LARGE = 'LARGE',
}

interface Props {
  size?: SpinnerSizes;
  light?: boolean;
}

const spinnerSizeMap = {
  [SpinnerSizes.SMALL]: '1.25rem',
  [SpinnerSizes.REGULAR]: '2.25rem',
  [SpinnerSizes.LARGE]: '3rem',
};

const SpinnerLightCSS = css`
  border-left: 0.125em solid rgba(255, 255, 255, 0.15);
  border-right: 0.125em solid rgba(255, 255, 255, 0.15);
  border-bottom: 0.125em solid rgba(255, 255, 255, 0.15);
  border-top: 0.125em solid rgba(255, 255, 255, 0.8);
`;

const SpinnerDarkCSS = css`
  border-left: 0.125em solid rgba(0, 0, 0, 0.15);
  border-right: 0.125em solid rgba(0, 0, 0, 0.15);
  border-bottom: 0.125em solid rgba(0, 0, 0, 0.15);
  border-top: 0.125em solid rgba(0, 0, 0, 0.8);
`;

const Spinner = styled.span<Props>`
  display: block;
  height: 1em;
  width: 1em;
  margin: 0 0.5rem 0 0;
  -webkit-animation: rotation 1s infinite linear;
  animation: rotation 1s infinite linear;
  ${({ light }) => (light ? SpinnerLightCSS : SpinnerDarkCSS)}
  border-radius: 50%;
  font-size: ${({ size = SpinnerSizes.REGULAR }) => spinnerSizeMap[size]};
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  @keyframes rotation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`;

export function CenteredSpinner(props: Props) {
  return (
    <div className="flex w-full h-full">
      <div className="m-auto">
        <Spinner {...props} />
      </div>
    </div>
  );
}

export default Spinner;
