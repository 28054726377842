import React from 'react';
import styled from 'styled-components';

import { times } from '../utils/array';

interface CellProps {
  align?: 'left' | 'right' | 'center';
}

export const Row = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_5};
  &:hover,
  &:focus {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.GREY_7};
  }
`;

export const RowNoHover = styled(Row)`
  &:hover {
    cursor: default;
  }
`;

export const EditRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_4};
  background-color: ${({ theme }) => theme.colors.GREY_7};
  }
`;

export const Header = styled.thead`
  & tr:hover {
    cursor: default;
    background-color: initial;
  }
`;

const commonCellStyle = `
    padding: 0.4rem 0.8rem;
    max-width: 20rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    & span {
      white-space: nowrap;
    }
`;

export const HeaderCell = styled.th<CellProps>`
  border: 0;
  text-align: ${({ align }) => align ?? 'left'};
  ${commonCellStyle}
`;

export const EmptyHeaderCell = styled.td<CellProps>`
  border: 0;
  text-align: ${({ align }) => align ?? 'left'};
  ${commonCellStyle}
`;

export const Cell = styled.td<CellProps>`
  line-height: 1.5rem;
  text-align: ${({ align }) => align ?? 'left'};
  ${commonCellStyle}
  &:last-child {
    border-right: none;
  }
`;

export const CellForDropdown = styled(Cell)`
  padding: 0;
  align-items: center;
  overflow: visible;
`;

export default styled.table`
  width: 100%;
  border: 0;
  border-collapse: collapse;
`;

const SkeletonData = styled.div`
  ${({ theme }) => theme.presets.SKELETON_VIEW};
  height: 2rem;
  width: 100%;
`;

interface SkeletonProps {
  numberOfRows: number;
  numberOfColumns: number;
}

export function SkeletonBody({ numberOfColumns, numberOfRows }: SkeletonProps) {
  return (
    <>
      {times(numberOfRows).map((index) => (
        <Row key={index}>
          {times(numberOfColumns).map((index) => (
            <Cell key={index}>
              <SkeletonData />
            </Cell>
          ))}
        </Row>
      ))}
    </>
  );
}
