import React from 'react';
import { FormSpy, useForm } from 'react-final-form';
import { AnyObject, FormState } from 'final-form';

export function AutoSave() {
  const form = useForm();

  function handleBlur({
    active,
    dirty,
    dirtySinceLastSubmit,
    valid,
  }: FormState<AnyObject>) {
    if (active === undefined && valid && (dirty || dirtySinceLastSubmit)) {
      form.submit();
    }
  }

  return (
    <FormSpy
      onChange={handleBlur}
      subscription={{
        active: true,
        dirty: true,
        dirtySinceLastSubmit: true,
        valid: true,
      }}
    />
  );
}
