import { createGlobalStyle } from 'styled-components';

/**
 * This helper injects the global styles specific to this project.
 *
 * In Short: 1) it sets the 'box-sizing' to 'border-box' for every element 2) it
 * sets Source Sans Pro as default font (with fallback to Helvetica) 3) it enables some
 * text smoothing things 4) it disables padding and margin on the body.
 */
const ProjectStyles = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
  }
  body {
    height: 100%;
    overflow-y: auto;
    /* No ugly browser default padding/margin */
    padding: 0;
    margin: 0;
    /* Smooth text hack by Daniël */
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-smooth: always;
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 0.0625rem 0.0625rem 0.0625rem rgba(0,0,0,.004);
    color: ${({ theme }) => theme.colors.TEXT};
    * { /* Give it some specificity by putting this in body */
      box-sizing: border-box;
      font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
      padding: 0;
      margin: 0;
    }

    #root {
      height: 100%;
      width: 100%;
      position: relative;
    }
  }
`;

export default ProjectStyles;
