/**
 * Unflattens a flattened object
 * E.g.:
 * {
 *   "something.key": "value"
 * }
 *
 * becomes
 *
 * {
 *  something: {
 *    key: value
 *  }
 * }
 */
export default function unflattenObject(obj: Record<string, any>) {
  return Object.entries(obj)
    .map(([key, value]) => ({
      index: key.split('.')[0],
      key: key.split('.')[1],
      value,
    }))
    .reduce((errors, { index, key, value }) => {
      errors[index] =
        key !== undefined ? { ...errors[index], [key]: value } : value;

      return errors;
    }, {});
}
