/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { Heading1 } from './Typography';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage?: string;
}

const CHUNK_ERROR = 'ChunkLoadError';

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    if (error.name === CHUNK_ERROR) {
      return window.location.reload();
    }

    return { hasError: true, errorMessage: error.message };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Heading1>{this.state.errorMessage}</Heading1>;
    }

    return this.props.children;
  }
}
