import { gql } from '@apollo/client';

export const BRAND_INFORMATION_QUERY = 'BrandInformationQuery';

export const BRAND_INFORMATION = gql`
  query BrandInformationQuery($categoryId: String) {
    brandInformation(categoryId: $categoryId) {
      brandColor
      instanceLogo
      providerLogo
      name
    }
  }
`;

export const CATEGORY_BRAND_INFORMATION = gql`
  query CategoryBrandInformationQuery($categoryId: String!) {
    categoryBrandInformation(categoryId: $categoryId) {
      id
      brandColor
      instanceLogo
      category {
        id
      }
    }
  }
`;

export const CATEGORIES = gql`
  query CategoriesQuery($active: Boolean, $selfService: Boolean) {
    categories(active: $active, selfService: $selfService) {
      id
      label
      description
      key
      externalKey
      selfService
    }
  }
`;

export const LANGUAGES = gql`
  query LanguagesQuery {
    languages {
      id
      label
      locale
      default
    }
  }
`;

export const META = gql`
  query MetaQuery($contextId: String!) {
    context(contextId: $contextId) {
      meta
    }
  }
`;

export const TRANSLATIONS = gql`
  query TranslationsQuery($language: String!, $moduleId: String!) {
    staticTranslations(input: { language: $language, moduleId: $moduleId }) {
      translations
    }
  }
`;
