import { removeTypename } from './removeKeys';

export function createFilterObject(object?: Object): Object | undefined {
  if (object === undefined) {
    return undefined;
  }

  const newObj = Object.keys(object)
    .filter((key) => object[key] !== null)
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: object[key],
      }),
      {},
    );

  return removeTypename(newObj);
}
