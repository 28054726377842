import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  infoMessage?: String;
}

export default function InputHelperText({ infoMessage }: Props) {
  if (!infoMessage) {
    return null;
  }

  return (
    <label className="relative block mt-3 mb-2 text-sm text-gray-400 select-none">
      <FontAwesomeIcon className="mr-1" icon={['fas', 'info-circle']} />
      {infoMessage}
    </label>
  );
}
