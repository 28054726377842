import { useMemo } from 'react';
import { useLocation } from 'react-router';

export default function useQueryParams(): { [key: string]: string } {
  const { search } = useLocation();

  return useMemo(() => {
    return Array.from(new URLSearchParams(search).entries()).reduce(
      (paramObject, [key, value]) => {
        paramObject[key] = value;

        return paramObject;
      },
      {},
    );
  }, [search]);
}
