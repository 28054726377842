export function reorderList<T>(
  list: T[],
  startIndex: number,
  endIndex: number,
): T[] {
  const reorderList = Array.from(list);
  const [removed] = reorderList.splice(startIndex, 1);
  reorderList.splice(endIndex, 0, removed);

  return reorderList.map((item, index) => ({
    ...item,
    order: index + 1,
  }));
}
