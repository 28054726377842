import * as React from 'react';
import ExternalModal from 'react-modal';
import styled from 'styled-components';

import ContentWithoutScrollbar from './contentWithoutScrollbar';
import { Body, CloseIcon, Header, SubTitle, Title, Wrapper } from './styles';

// === Interfaces === //
interface ModalProperties {
  /** The normal React children. */
  children: React.ReactNode;
  /** String indicating how the content container should be announced to screenreaders. */
  contentLabel?: string;
  /** Additional className for styling purposes e.g. styled(Modal). */
  className?: string;
  /** Indicates whether or not the Modal is visible on screen. */
  isOpen?: boolean;
  /** Fit modal to content */
  fitToContent?: boolean;
  /** Callback executed when clicking the cross or clicking outside the Modal. */
  onClose: () => void;
  /** Tells the modal whether or not it should fadeIn when opened and fadeOut when closed. */
  shouldAnimate?: boolean;
  /** When true hides the scrollbar from the document body. */
  shouldHideScrollbar?: boolean;
  shouldHideClose?: boolean;
  /** The title shown in the header. */
  title: string;
  /** The subtitle shown in the header. */
  subTitle?: string;
}

const ModalComponent = React.memo(
  ({
    children,
    className,
    contentLabel,
    fitToContent,
    isOpen = true,
    onClose,
    shouldAnimate = true,
    shouldHideClose,
    shouldHideScrollbar = true,
    subTitle,
    title,
  }: ModalProperties) => {
    const contentClassName = `${className}__content`;
    const overlayClassName = `${className}__overlay`;

    return (
      <ExternalModal
        ariaHideApp={false}
        bodyOpenClassName="modal-body-open"
        className={`modal-content${contentClassName}`}
        closeTimeoutMS={shouldAnimate ? 300 : 0}
        contentLabel={contentLabel}
        htmlOpenClassName="modal-html-open"
        isOpen={isOpen}
        onRequestClose={onClose}
        overlayClassName={overlayClassName}
        portalClassName={className}
      >
        <Wrapper className="modal-wrapper">
          <Header className="modal-header">
            <Title>{title}</Title>
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
            <div>{!shouldHideClose && <CloseIcon onClick={onClose} />}</div>
          </Header>
          <Body className="modal-body">
            {shouldHideScrollbar ? (
              <ContentWithoutScrollbar>{children}</ContentWithoutScrollbar>
            ) : (
              children
            )}
          </Body>
        </Wrapper>
      </ExternalModal>
    );
  },
);

export const Modal = styled(ModalComponent)`
  // The CSS that makes modals animate (ReactModal__* CSS classes).
  // This is not named after our own classNames since this is not possible.
  ${({ shouldAnimate = true }) =>
    shouldAnimate
      ? `
    .ReactModal__Overlay {
      transition: opacity 300ms ease-in-out;
      opacity: 0;

      &--after-open {
        opacity: 1;
      }

      &--before-close {
        opacity: 0;
      }
    }
  `
      : ''}

  // This is the default styling for the overlay.
  &__overlay {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }

  // This isthe default styling for the content.
  &__content {
    background-color: white;
    border: none;
    border-radius: 0;
    bottom: auto;
    font-weight: normal;
    left: 0;
    outline: 0;
    overflow: visible;
    padding: 0;
    position: relative;
    right: 0;
    top: 0;
    width: 60rem;
    max-width: 96%;

    ${({ fitToContent = false }) =>
      fitToContent ? 'width: auto !important;' : ''}

    ${({ theme }) => theme.breakPoints.medium} {
      min-width: unset;
      max-width: 90%;
      width: 70rem;
    }
  }
`;

export default Modal;
