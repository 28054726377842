import styled from 'styled-components';

export const FormFooter = styled.div`
  align-items: center;
  display: flex;
  margin-top: 2rem;

  > button + button {
    margin-left: auto;
  }
`;
