import { MutableRefObject, useEffect, useState } from 'react';

import { useGoogleLoader } from './useGoogleLoader';

const DEFAULT_ZOOM = 17;
const DEFAULT_CENTER = {
  lat: parseFloat(
    process.env.MAP_CENTER_LAT != null && process.env.MAP_CENTER_LAT !== ''
      ? process.env.MAP_CENTER_LAT
      : '55.738156',
  ),
  lng: parseFloat(
    process.env.MAP_CENTER_LNG != null && process.env.MAP_CENTER_LNG !== ''
      ? process.env.MAP_CENTER_LNG
      : '12.398967',
  ),
};

export function useGoogleMap(
  target: MutableRefObject<HTMLDivElement | null>,
  options?: google.maps.MapOptions,
) {
  const { isLoaded } = useGoogleLoader();
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    let isSubscribed = true;

    if (isLoaded && isSubscribed && target.current != null) {
      const map = new google.maps.Map(target.current, {
        center: options?.center ?? DEFAULT_CENTER,
        zoom: options?.zoom ?? DEFAULT_ZOOM,
        disableDefaultUI: true,
        gestureHandling: 'cooperative',
      });

      setMap(map);
    }

    return () => {
      isSubscribed = false;
    };
    // TODO: Passing options object entirely is causing infinite re-renders
  }, [isLoaded, target, options?.center, options?.zoom]);

  return map;
}
