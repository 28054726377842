import { Colors } from '../colors';

import generateSkeletonViewStyles from './skeletonView';

export interface Presets {
  SKELETON_VIEW: string;
  SKELETON_VIEW_DARK: string;
}

export default function generatePresets(colors: Colors): Presets {
  const SKELETON_VIEW_PRESET = generateSkeletonViewStyles(colors);

  return {
    ...SKELETON_VIEW_PRESET,
  };
}
