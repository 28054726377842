import React from 'react';
import styled from 'styled-components';

const TagStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.GREY_7};
  border: 1px solid ${({ theme }) => theme.colors.GREY_4};
  font-size: 0.8125rem;
  padding: 0 0.25rem;
  display: inline-block;

  & + & {
    margin-left: 0.4rem;
  }
`;

interface Props {
  value: string;
}

export default function Tag({ value }: Props) {
  return <TagStyled>{value}</TagStyled>;
}
