import * as yup from 'yup';

export function trimStringValues<T>(values: Partial<T>): typeof values {
  return (
    values &&
    Object.keys(values).reduce((trimmed, key) => {
      const value = values[key];
      trimmed[key] = typeof value === 'string' ? value.trim() : value;

      return trimmed;
    }, {})
  );
}

export async function validateSchema(
  schema: yup.ObjectSchema | yup.ArraySchema<any>,
  values: any,
): Promise<Record<string, string> | undefined> {
  try {
    await schema.validate(values, { abortEarly: false });

    return;
  } catch (error) {
    return mapValidationError(error);
  }
}

export function mapValidationError(error: yup.ValidationError) {
  return error.inner.reduce(
    (
      previousErrors: { [key: string]: string },
      innerError: yup.ValidationError,
    ) => {
      previousErrors[innerError.path] = innerError.message;

      return previousErrors;
    },
    {},
  );
}

export function mapValidationErrorToBoolean(error: yup.ValidationError) {
  return error.inner.reduce(
    (
      previousErrors: { [key: string]: boolean },
      innerError: yup.ValidationError,
    ) => {
      previousErrors[innerError.path] = true;

      return previousErrors;
    },
    {},
  );
}
