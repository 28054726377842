import * as React from 'react';
import cx from 'clsx';

interface Props {
  required?: boolean;
  className?: string;
  htmlFor: string | undefined;
}

export const Label = ({
  children,
  className,
  htmlFor,
  required,
}: React.PropsWithChildren<Props>) => {
  const Tag = htmlFor ? 'label' : 'div';

  return (
    <Tag
      className={cx('block m-0 mb-2 mt-3 text-gray-600 select-none', className)}
      htmlFor={htmlFor ? htmlFor : undefined}
    >
      {children}
      &nbsp;
      {required && '*'}
    </Tag>
  );
};

export default Label;
