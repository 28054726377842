export interface FontFamily {
  '27_SANS': string;
  SOURCE_SANS_PRO: string;
}

export default function generateFontFamily() {
  return {
    '27_SANS': `'27Sans', 'Source Sans Pro', Helvetica, Arial, sans-serif`,
    SOURCE_SANS_PRO: `'Source Sans Pro', Helvetica, Arial, sans-serif`,
  };
}
