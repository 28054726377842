import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputFieldProps } from './_common/types';

interface Props extends InputFieldProps<string> {
  renderModal: (
    onClose: () => void,
    onChange: (value: string) => void,
    value: string | undefined,
  ) => JSX.Element;
}

export default function ModalField({
  name,
  onBlur,
  onChange,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  renderModal,
  value,
}: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  function closeModal() {
    if (onBlur) {
      onBlur();
    }

    if (onMouseLeave) {
      onMouseLeave();
    }

    setModalOpen(false);
  }

  function openModal() {
    setModalOpen(true);
  }

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Tab' && onMouseEnter) {
        onMouseEnter(event);
      }
    },
    [onMouseEnter],
  );

  return (
    <>
      {modalOpen && renderModal(closeModal, onChange, value)}
      <button
        aria-label={t('booking:address.address')}
        className="w-full h-full p-4 overflow-hidden bg-gray-100 outline-none cursor-pointer resize-none min-h-20 focus:border focus:border-gray-400"
        onBlur={onBlur}
        onClick={openModal}
        onFocus={onFocus}
        onKeyUp={handleKeyPress}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        type="button"
      />
    </>
  );
}
