import Color from 'color';

export interface Colors {
  BRAND: string;
  BRAND_LIGHT: string;
  BRAND_LIGHTEST: string;
  BRAND_DARK: string;
  BRAND_DARKEST: string;

  HOVER: string;

  ERROR: string;
  ERROR_LIGHT: string;
  ERROR_DARK: string;
  SUCCESS: string;
  SUCCESS_LIGHT: string;
  SUCCESS_DARK: string;
  WARNING: string;
  WARNING_LIGHT: string;
  WARNING_DARK: string;

  BLACK: string;
  TEXT: string;
  GREY_1: string;
  GREY_2: string;
  GREY_3: string;
  GREY_4: string;
  BORDER: string;
  GREY_5: string;
  GREY_6: string;
  BG: string;
  GREY_7: string;
  WHITE: string;
  TRANSPARENT: string;
}

export default function generateColors(brandColor: string): Colors {
  return {
    BRAND: brandColor,
    BRAND_LIGHT: Color(brandColor).lighten(0.2).hex(),
    BRAND_LIGHTEST: Color(brandColor).lighten(0.6).hex(),
    BRAND_DARK: Color(brandColor).darken(0.2).hex(),
    BRAND_DARKEST: Color(brandColor).darken(0.4).hex(),
    HOVER: Color(brandColor).darken(0.2).hex(),

    ERROR: '#DC2626', //tailwind-red-600 (enough contrast for accessibility according to universal design)
    ERROR_LIGHT: '#FEE2E2', // tailwind-red-100, only use for backgrounds etc
    ERROR_DARK: '#7F1D1D', // tailwind-red-900 only use for text on _light background
    SUCCESS: '#047857', //tailwind-green-700 (enough contrast for accessibility according to universal design)
    SUCCESS_LIGHT: '#D1FAE5', // tailwind-green-100, only use for backgrounds etc
    SUCCESS_DARK: '#064E3B', // tailwind-green-900, only use for text on _light background
    WARNING: '#F59E0B', // tailwind-yellow-700 (enough contrast for accessibility according to universal design)
    WARNING_LIGHT: '#B45309', // tailwind-yellow-100, only use for backgrounds etc
    WARNING_DARK: '#78350F', // tailwind-yellow-900, only use for text on _light background

    BLACK: '#262626',
    TEXT: '#262626',

    GREY_1: '#535C5E',
    GREY_2: '#767676',
    GREY_3: '#989898',

    GREY_4: '#E1E1E1',
    BORDER: '#E1E1E1',

    GREY_5: '#EDEDED',
    GREY_6: '#EDEDED',
    BG: '#EDEDED',

    GREY_7: '#F7F7F7',
    WHITE: '#FFFFFF',
    TRANSPARENT: 'rgba(255, 255, 255, 0)', // Because css does not animate 'transparent' string color
  };
}
