import * as React from 'react';
import cx from 'clsx';

import { TextInputFieldProps } from './_common/types';

export default function StringField({
  browserAutoComplete,
  className,
  disabled,
  name,
  onBlur,
  onChange,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  pattern,
  placeholder,
  shouldFocus,
  tabIndex,
  type,
  value,
}: TextInputFieldProps) {
  const inputRef: React.Ref<HTMLInputElement> = React.useRef(
    {} as HTMLInputElement,
  );

  const focus = React.useCallback(() => {
    if (inputRef.current && inputRef.current.focus) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  React.useLayoutEffect(() => {
    if (shouldFocus) {
      focus();
      onFocus?.();
    }
  }, [shouldFocus, focus]);

  const onChangeCb = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange],
  );

  return (
    <div className={cx('w-full', className)}>
      <input
        aria-describedby={`${name}-error`}
        autoComplete={browserAutoComplete ? 'on' : 'off'}
        className={cx(
          'outline-none p-3 w-full leading-normal border border-gray-300 focus:border-gray-500 transition duration-200 ease-out',
          disabled
            ? 'bg-gray-300 cursor-default text-gray-700'
            : 'bg-white cursor-text text-gray-700',
        )}
        disabled={disabled}
        id={name}
        name={name}
        onBlur={onBlur}
        onChange={onChangeCb}
        onFocus={onFocus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        pattern={pattern}
        placeholder={placeholder}
        ref={inputRef}
        tabIndex={tabIndex}
        type={type}
        value={value}
      />
    </div>
  );
}
