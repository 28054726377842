import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import styled from 'styled-components';

import Spinner, { SpinnerSizes } from '../Spinner';

interface Props {
  ariaLabel?: string;
  disabled?: boolean;
  className?: string;
  style?: Record<string, string>;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (
    event?: React.SyntheticEvent<
      HTMLButtonElement | HTMLAnchorElement | HTMLFormElement
    >,
  ) => void;
  onKeyDown?: React.KeyboardEventHandler;
  onMouseEnter?: (
    event?: React.SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => void;
  onMouseLeave?: (
    event?: React.SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => void;
  onFocus?: (
    event?: React.FocusEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => void;
  onBlur?: (
    event?: React.FocusEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => void;
  loading?: boolean;
  icon?: IconProp;
  rightIcon?: IconProp;
  form?: string;
  tabIndex?: number;
  href?: string;
}

const Icon = styled(FontAwesomeIcon)`
  margin: 0 0.5rem 0 0;
  display: block;
  position: relative;
`;

const RightIcon = styled(Icon)`
  margin: 0 0 0 0.5rem;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled.div<{ opacity: number }>`
  opacity: ${({ opacity }) => opacity};
  transition: opacity 80ms ease-in-out;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

function Button({
  ariaLabel,
  children,
  className,
  href,
  icon,
  loading = false,
  rightIcon,
  ...props
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const ariaLabelAll = loading
    ? t('common:button.loading')
    : ariaLabel
    ? ariaLabel
    : undefined;

  if (href) {
    return (
      <a {...props} aria-label={ariaLabelAll} className={className} href={href}>
        <div className="__content">
          {icon && <Icon className="__leftIcon" icon={icon} />}
          {children}
          {rightIcon && <RightIcon className="__rightIcon" icon={rightIcon} />}
        </div>
      </a>
    );
  }

  return (
    <button {...props} aria-label={ariaLabelAll} className={className}>
      {loading && (
        <SpinnerContainer>
          <Spinner size={SpinnerSizes.SMALL} light />
        </SpinnerContainer>
      )}
      <ContentContainer className="__content" opacity={loading ? 0 : 1}>
        {icon && <Icon className="__leftIcon" icon={icon} />}
        {children}
        {rightIcon && <RightIcon className="__rightIcon" icon={rightIcon} />}
      </ContentContainer>
    </button>
  );
}

// === Primary Button === //
export const StyledPrimaryButton = styled(Button)`
  text-decoration: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  color: ${({ disabled, theme: { colors } }) =>
    disabled ? colors.GREY_3 : colors.WHITE};
  background-color: ${({ disabled, theme: { colors } }) =>
    disabled ? colors.GREY_4 : colors.BRAND};
  transition: background-color 200ms cubic-bezier(1, 0, 0, 1),
    color 200ms cubic-bezier(1, 0, 0, 1);

  &:focus {
    outline: blue;
    background-color: ${({ theme: { colors } }) => colors.BRAND_DARK};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    width: 0;
    transition: ${({ disabled }) =>
      disabled ? 'none' : 'width 400ms cubic-bezier(1, 0, 0, 1)'};

    background-color: ${({ theme: { colors } }) => colors.BRAND_DARK};
  }

  &:hover::before,
  &:focus::before,
  :active::before {
    width: ${({ disabled }) => (disabled ? '0' : '100%')};
  }

  & .__content {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const PrimaryButton = ({
  children,
  className,
  ...props
}: PropsWithChildren<Props>) => (
  <StyledPrimaryButton
    {...props}
    className={cx(
      'outline-none box-shadow-none relative flex content-center justify-center p-3 w-56 whitespace-normal font-normal border-none box-border',
      className,
    )}
  >
    {children}
  </StyledPrimaryButton>
);

export const PrimaryBlackButton = styled(PrimaryButton)`
  background-color: ${({ disabled, theme: { colors } }) =>
    disabled ? colors.GREY_4 : colors.BLACK};
  color: ${({ disabled, theme: { colors } }) =>
    disabled ? colors.GREY_3 : colors.WHITE};
`;

export const SecondaryButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.colors.TRANSPARENT};
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.GREY_3 : theme.colors.TEXT};
  border: 1px solid
    ${({ disabled, theme }) =>
      disabled ? theme.colors.GREY_3 : theme.colors.BLACK};

  &:hover,
  &:focus,
  :active {
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.GREY_3 : theme.colors.WHITE};
    background-color: inherit;
  }

  &::before {
    background-color: ${({ theme: { colors } }) => colors.BLACK};
  }
`;

export const SecondaryBrandButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.colors.TRANSPARENT};
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.GREY_3 : theme.colors.BRAND};
  border: 1px solid
    ${({ disabled, theme }) =>
      disabled ? theme.colors.GREY_3 : theme.colors.BRAND};

  &:hover,
  &:focus,
  :active {
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.GREY_3 : theme.colors.WHITE};
    background-color: inherit;
  }

  &::before {
    background-color: ${({ theme: { colors } }) => colors.BRAND};
  }
`;

export const SecondaryWhiteButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.colors.BRAND};
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.GREY_3 : theme.colors.WHITE};
  border: 1px solid
    ${({ disabled, theme }) =>
      disabled ? theme.colors.GREY_3 : theme.colors.WHITE};
  width: calc(12.5rem - 2px);
  height: calc(1.25rem - 2px);

  &:hover,
  &:focus,
  :active {
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.GREY_3 : theme.colors.BRAND};
  }

  &::before {
    background-color: ${({ theme: { colors } }) => colors.WHITE};
  }
`;

export const TertiaryButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.TRANSPARENT};
  color: ${({ theme }) => theme.colors.BRAND};
  height: initial;
  width: auto;
  margin: 0;
  padding: 8px 2px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.BRAND};
  box-shadow: 0;
  transition: all 0.3s ease;

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.colors.TRANSPARENT};
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.BRAND};
  }
`;

export const TertiaryButtonWhite = styled(TertiaryButton)`
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const TertiaryButtonBlack = styled(TertiaryButton)`
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.GREY_3 : theme.colors.BLACK};
  border-bottom-color: ${({ theme }) => theme.colors.BLACK};

  .__content {
    display: flex;
    align-items: center;
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.BLACK};
  }

  & svg {
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.GREY_3 : theme.colors.BLACK};
    width: 0.8rem;
    height: 0.8rem;
  }

  & svg {
    transition: 400ms cubic-bezier(1, 0, 0, 1);
    transform: translateX(0);
  }

  &:hover svg.__leftIcon,
  &:focus svg.__leftIcon,
  &:active svg.__leftIcon {
    transform: ${({ disabled }) =>
      disabled ? 'translateX(0)' : 'translateX(-0.25rem)'};
  }

  &:hover svg.__rightIcon,
  &:focus svg.__rightIcon,
  &:active svg.__rightIcon {
    transform: ${({ disabled }) =>
      disabled ? 'translateX(0)' : 'translateX(0.25rem)'};
  }
`;

export const FlatButton = styled(Button)`
  border: none;
  background: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.GREY_3};
  outline: none;

  svg {
    color: currentColor;
    margin-right: 0.5rem;
  }
`;

export default PrimaryButton;
