import React, { useEffect, useState } from 'react';

export const useTimeoutState = <S>(
  initial: S,
  timeoutMs: number,
  fallback?: S,
): [S, React.Dispatch<React.SetStateAction<S>>] => {
  const [state, setState] = useState<S>(initial);

  useEffect(() => {
    const timer = setTimeout(() => setState(fallback ?? initial), timeoutMs);

    return () => clearTimeout(timer);
  }, [state, fallback, timeoutMs]);

  return [state, setState];
};
