import Color from 'color';

import { BrandInformationQuery_brandInformation } from '../../types/api';

import breakPoints, { BreakPoints } from './breakPoints';
import generateColors, { Colors } from './colors';
import generateFontFamily, { FontFamily } from './fontFamily';
import generatePresets, { Presets } from './presets';
import variables, { Variables } from './variables';

const DEFAULT_BRAND = '#DC0000';

export interface Theme {
  name?: string;
  colors: Colors;
  variables: Variables;
  fontFamily: FontFamily;
  presets: Presets;
  breakPoints: BreakPoints;
  instanceLogo?: string | null;
  providerLogo?: string | null;
}

function generateTheme(
  brandConfiguration?: BrandInformationQuery_brandInformation | null,
): Theme {
  let parsedBrandColor: Color | null = null;

  try {
    // check if brandColor is a valid hex color string
    parsedBrandColor = Color(brandConfiguration?.brandColor);
  } catch (e) {
    // swallow error
  }

  const colors = generateColors(
    parsedBrandColor != null ? parsedBrandColor.toString() : DEFAULT_BRAND,
  );
  const fontFamily = generateFontFamily();
  const presets = generatePresets(colors);

  return {
    name: brandConfiguration?.name,
    colors,
    variables,
    fontFamily,
    presets,
    breakPoints,
    instanceLogo: brandConfiguration?.instanceLogo,
    providerLogo: brandConfiguration?.providerLogo,
  };
}

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}

export default generateTheme;
