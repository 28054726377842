import { Colors } from '../colors';

interface SkeletonViewStyles {
  SKELETON_VIEW: string;
  SKELETON_VIEW_DARK: string;
}

function skeletonViewStyle(
  primaryColor: string,
  secondaryColor: string,
): string {
  return `
  background: ${primaryColor};
  background-color: ${primaryColor};
  border-radius: 0.25rem;
  animation: loading 1.7s infinite linear;
  background-image: -webkit-linear-gradient(
    to right,
    ${primaryColor} 0%,
    ${secondaryColor} 20%,
    ${primaryColor} 40%,
    ${primaryColor} 100%
  );
  background-image: linear-gradient(
    to right,
    ${primaryColor} 0%,
    ${secondaryColor} 20%,
    ${primaryColor} 40%,
    ${primaryColor} 100%
  );
  background-repeat: no-repeat;
  @keyframes loading {
    0% {
      background-position: -100px;
    }
    100% {
      background-position: 500px;
    }
  }
`;
}

export default function generateSkeletonViewStyles(
  colors: Colors,
): SkeletonViewStyles {
  const SKELETON_VIEW = skeletonViewStyle(colors.GREY_6, colors.GREY_7);
  const SKELETON_VIEW_DARK = skeletonViewStyle(colors.GREY_4, colors.GREY_5);

  return {
    SKELETON_VIEW,
    SKELETON_VIEW_DARK,
  };
}
