import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface CrossProperties {
  className?: string;
  onClick: () => void;
}

const Cross = React.memo(({ className, onClick }: CrossProperties) => {
  const { t } = useTranslation();

  return (
    <button
      aria-label={t('translation:button.close')}
      className={clsx(
        'p-2 focus:bg-gray-300 rounded cursor-pointer',
        className,
      )}
      onClick={onClick}
    >
      <svg
        height="18"
        role="img"
        viewBox="0 0 24 24"
        width="18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24 2.4L14.4 12l9.6 9.6-2.4 2.4-9.6-9.6L2.4 24 0 21.6 9.6 12 0 2.4 2.4 0 12 9.6 21.6 0z"
          fill="#000"
        />
      </svg>
    </button>
  );
});

export default Cross;
