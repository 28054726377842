import * as React from 'react';
import cx from 'clsx';

import { TextInputFieldProps } from './_common/types';

interface Props extends TextInputFieldProps {
  /** Indicates whether or not the textarea should try and resize itself. */
  shouldAutoResize?: boolean;
}

export default function TextField({
  browserAutoComplete,
  className,
  disabled,
  name,
  onBlur,
  onChange,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  placeholder,
  shouldAutoResize = true,
  shouldFocus,
  value,
}: Props) {
  const inputRef: React.Ref<HTMLTextAreaElement> = React.useRef(
    {} as HTMLTextAreaElement,
  );
  const animationFrame: { current?: number | null } = React.useRef(null);

  const autosize = React.useCallback(() => {
    if (shouldAutoResize) {
      if (animationFrame.current) {
        cancelAnimationFrame(animationFrame.current);
      }
      // Read frame
      animationFrame.current = requestAnimationFrame(() => {
        if (!inputRef.current) {
          return;
        }
        inputRef.current.style.height = '0px';
        const currentScrollHeight = inputRef.current.scrollHeight;
        // Write frame
        inputRef.current.style.height = `${currentScrollHeight || 34}px`;
        animationFrame.current = null;
      });
    }
  }, [inputRef, animationFrame]);

  // On mount try to autosize.
  React.useLayoutEffect(() => {
    autosize();
  }, []);

  const focus = React.useCallback(() => {
    if (inputRef.current && inputRef.current.focus) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  React.useLayoutEffect(() => {
    if (shouldFocus) {
      focus();
    }
  }, [shouldFocus, focus]);

  const onChangeCb = React.useCallback(
    (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange],
  );

  return (
    <div className={className}>
      <textarea
        aria-describedby={`${name}-error`}
        autoComplete={browserAutoComplete ? 'on' : 'off'}
        className={cx(
          'outline-none p-4 w-full min-h-24 border border-gray-300 focus:border-gray-400 cursor-pointer resize-none transition duration-200 ease-out',
          disabled
            ? 'bg-gray-300 cursor-default text-gray-700'
            : 'bg-white cursor-text text-gray-700',
        )}
        disabled={disabled}
        id={name}
        onBlur={onBlur}
        onChange={onChangeCb}
        onFocus={onFocus}
        onInput={autosize}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        placeholder={placeholder}
        ref={inputRef}
        value={value}
      />
    </div>
  );
}
