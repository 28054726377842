import React, { ReactNode, useEffect, useRef } from 'react';

import { useGoogleMap } from './utils/hooks/google/useGoogleMap';

interface Props extends google.maps.MapOptions {
  children?: (map: google.maps.Map) => ReactNode;
}

export function Map({ children, ...options }: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const map = useGoogleMap(ref, options);

  return (
    <div className="w-full h-full" ref={ref}>
      {map && children?.(map)}
    </div>
  );
}

export function Marker({
  map,
  position,
}: {
  position: {
    lat: number;
    lng: number;
  };
  map: google.maps.Map;
}) {
  const markerRef = useRef<google.maps.Marker>();

  useEffect(() => {
    markerRef.current?.setMap(map);
    markerRef.current = new google.maps.Marker({ position, map });

    map.setCenter({
      lat: position.lat,
      lng: position.lng,
    });
  }, [map, position]);

  return null;
}