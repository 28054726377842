import { ApolloError } from '@apollo/client';

import i18n from '../../I18n';

enum ErrorCode {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

enum DomainError {
  UNAUTHENTICATED = 'ACCESS DENIED',
  DUPLICATE_APPOINTMENT = 'DuplicateAppointmentError',
  VALIDATION_ERROR = 'ValidationError',
}

export function isUnauthenticatedError({ graphQLErrors }: ApolloError) {
  return graphQLErrors?.some(
    (error) =>
      error.extensions?.code === ErrorCode.UNAUTHENTICATED ||
      error.message.toUpperCase().startsWith(DomainError.UNAUTHENTICATED),
  );
}

export function isDuplicateAppointmentError({ graphQLErrors }: ApolloError) {
  return graphQLErrors?.some(
    (error) =>
      error.extensions?.exception.name === DomainError.DUPLICATE_APPOINTMENT,
  );
}

export function isValidationError({ graphQLErrors }: ApolloError) {
  return graphQLErrors?.some(
    (error) =>
      error.extensions?.exception.name === DomainError.VALIDATION_ERROR,
  );
}

/**
 * Maps apollo errors to a specific key by checking different properties of the
 * error. This key can be used to display an appropriate message with i18n.
 * @param error The thrown apollo error
 * @return key Use this to get a translated message from i18n
 * */
export default function mapApolloError(error: ApolloError): string {
  if (isUnauthenticatedError(error)) {
    return i18n.t('errors.timeout');
  }

  if (isDuplicateAppointmentError(error)) {
    return i18n.t('booking:main_form.confirm_modal.appointment_unavailable');
  }

  if (isValidationError(error)) {
    const validationError = error.graphQLErrors?.find(
      (error) =>
        error.extensions?.exception.name === DomainError.VALIDATION_ERROR,
    );

    return i18n.t('booking:main_form.confirm_modal.appointment_invalid', {
      fields: (validationError?.extensions?.exception?.fields ?? [])
        .map((field: string) => i18n.t(field))
        .join(', '),
    });
  }

  return i18n.t('errors.technical');
}
