const LOCALE_KEY = 'locale';

export type Locale = 'en' | 'da' | 'fr' | 'nl';

export function getLocale(): Locale | null {
  return localStorage.getItem(LOCALE_KEY) as Locale;
}

export function setLocale(locale: Locale) {
  localStorage.setItem(LOCALE_KEY, locale);
}
