export interface BreakPoints {
  extraLarge: string;
  large: string;
  medium: string;
  small: string;
  custom: (size: number) => string;
}

export default {
  extraLarge: '@media (min-width:1200px)',
  large: '@media (min-width:992px)',
  medium: '@media (min-width:768px)',
  small: '@media (min-width:480px)',
  custom: (size: number) => `@media (min-width:${size}px)`,
} as BreakPoints;
