import React, { PropsWithChildren, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Label = styled.div`
  height: calc(1.85rem - 2px);
  line-height: calc(1.85rem - 2px);
  border: 1px solid ${({ theme }) => theme.colors.BORDER};
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  cursor: initial;
  margin: 0.25rem 0.5rem 0.25rem 0;
`;

const DeleteButton = styled.button`
  pointer-events: auto;
  border: none;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: 0;
  margin-left: 0.2rem;
  background-color: transparent;
  cursor: pointer;
  height: 100%;

  &:focus {
    background-color: ${({ theme }) => theme.colors.GREY_4};
  }
`;

interface Props {
  value: string;
  onDelete: (value: string) => void;
}

function Value({ children, onDelete, value }: PropsWithChildren<Props>) {
  const handleDelete = useCallback(() => {
    onDelete(value);
  }, [value, onDelete]);

  return (
    <Label>
      {children}
      <DeleteButton onClick={handleDelete}>
        <FontAwesomeIcon icon={['fas', 'times']} />
      </DeleteButton>
    </Label>
  );
}

export default Value;
