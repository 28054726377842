import React, { ReactNode } from 'react';
import cx from 'clsx';

interface Props<T> {
  tabs: T[];
  active: T;
  onClick: (value: T) => void;
  renderTab?: (tab: T) => ReactNode;
}

export default function Tabs<T>(props: Props<T>) {
  const { active, onClick, renderTab, tabs } = props;

  return (
    <div className="flex flex-row mb-8 w-full border-b border-gray-300">
      {tabs.map((tab) => {
        return (
          <button
            className={cx(
              'mr-5 focus:text-brand-400 hover:text-brand-400 text-gray-600 leading-10 border-b-4 border-transparent cursor-pointer',
              active === tab ? 'border-brand-400 text-brand-400' : '',
            )}
            key={tab as unknown as string}
            onClick={() => onClick(tab)}
          >
            {renderTab ? renderTab(tab) : tab}
          </button>
        );
      })}
    </div>
  );
}
