import React, { useCallback, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useGoogleGeocoder } from '@common/utils/hooks/google/useGoogleGeocoder';
import {
  AddressDetailsWithTimeZone,
  getLocationInformation,
  mapAddressDetailsToString,
} from '@common/utils/hooks/google/utils';
import { FORM_ERROR, FormApi } from 'final-form';
import styled from 'styled-components';
import * as yup from 'yup';

import Button from '../../button';
import Modal from '../../modal';
import { trimStringValues, validateSchema } from '../../utils/form';
import ErrorMessage from '../_common/errorMessage';
import Label from '../_common/label';
import InputField from '../InputField';
import StringField from '../StringField';

import GoogleMapsSearch from './GoogleMapsSearch';

const FormLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 0.5rem;
`;

const AddressField = styled(InputField)`
  flex: 1;
  min-width: 100%;

  ${({ theme }) => theme.breakPoints.medium} {
    min-width: 0;
    width: 100%;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const GrowField = styled(AddressField)`
  flex: 2;
`;

const SaveButton = styled(Button)`
  width: 100%;
  margin: 1rem 0;
  ${({ theme }) => theme.breakPoints.medium} {
    width: 14rem;
    margin-right: auto;
  }
`;

interface Props {
  required?: boolean;
  address?: string;
  onClose: () => void;
  onSave: (value: string) => void;
}

export default function GoogleMapsAddressModal({
  address,
  onClose,
  onSave,
  required,
}: Props) {
  const { t } = useTranslation();
  const geocode = useGoogleGeocoder();

  const [searchString, setSearchString] = useState<string>('');
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);
  const [showForm, setShowForm] = useState(!!address);

  const initialValues = useMemo(
    () =>
      address
        ? JSON.parse(address)
        : {
            street: '',
            house_number: '',
            postal_code: '',
            city: '',
            country: '',
            place_name: '',
            unit_number: '',
            floor_number: '',
          },
    [address],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        street: yup.string().required(
          t('errors.required_error', {
            interpolation: { escapeValue: false },
            label: t('booking:address.street'),
          }),
        ),
        house_number: yup.string().required(
          t('errors.required_error', {
            label: t('booking:address.house_number'),
          }),
        ),
        postal_code: yup.string().required(
          t('errors.required_error', {
            label: t('booking:address.postal_code'),
          }),
        ),
        city: yup
          .string()
          .required(
            t('errors.required_error', { label: t('booking:address.city') }),
          ),
        country: yup
          .string()
          .required(
            t('errors.required_error', { label: t('booking:address.country') }),
          ),
      }),
    [t],
  );

  const handleSearchStringChange = useCallback(
    (form: FormApi<any>, searchString: string) => {
      const { change } = form;

      change('addressSearch', searchString);
      setSearchString(searchString);
    },
    [],
  );

  function handleAddressChange(
    form: FormApi<any>,
    address: AddressDetailsWithTimeZone,
  ) {
    const { batch, change } = form;

    const {
      house_number = '',
      street = '',
      postal_code = '',
      city = '',
      country = '',
      country_short = '',
      place_name = '',
      floor_number = '',
      unit_number = '',
      state = '',
      location = {
        lat: 0,
        lng: 0,
      },
      timezone = '',
    } = address;

    batch(() => {
      change('location', location);
      change('timezone', timezone);
      change('street', street);
      change('house_number', house_number);
      change('postal_code', postal_code);
      change('city', city);
      change('country', country);
      change('country_short', country_short);
      change('place_name', place_name);
      change('floor_number', floor_number);
      change('unit_number', unit_number);
      change('state', state);
    });

    setShouldRefetch(true);
    setShowForm(true);
  }

  function handleError() {
    setShowForm(true);
  }

  async function handleValidate(address: AddressDetailsWithTimeZone) {
    return required ? validateSchema(validationSchema, address) : {};
  }

  async function handleSubmit(address: AddressDetailsWithTimeZone) {
    try {
      if (shouldRefetch || initialValues.location === undefined) {
        const formattedAddress = mapAddressDetailsToString(address, ', ');
        const result = await geocode(formattedAddress);
        const { country_short, location, timezone } =
          (await getLocationInformation(result)) ?? {};

        Object.assign(address, {
          country_short,
          location,
          timezone,
        });
      }

      onSave(
        Object.entries(address)
          .filter(([key]) => !key.match(/(addressSearch|location)/))
          .every(([, value]) => !value)
          ? ''
          : JSON.stringify(trimStringValues(address)),
      );
      onClose();

      return undefined; // submit successful
    } catch {
      return {
        [FORM_ERROR]: t('booking:customer_form.address_modal.no_results'),
      };
    }
  }

  return (
    <Modal
      onClose={onClose}
      shouldHideScrollbar={false}
      title={t('booking:address.search_location')}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={handleValidate}
      >
        {({ form, handleSubmit, submitError, submitting, values }) => (
          <form id="addressForm" onSubmit={handleSubmit}>
            <div className="hidden">
              <Label htmlFor="googlemapssearch">
                {t('booking:address.search_location')}
              </Label>
            </div>
            <GoogleMapsSearch
              location={values.location}
              name="googlemapssearch"
              onChangeAddress={(address: AddressDetailsWithTimeZone) =>
                handleAddressChange(form, address)
              }
              onChangeSearchString={(searchString: string) =>
                handleSearchStringChange(form, searchString)
              }
              onError={handleError}
              searchString={searchString}
              showMap
            />
            {showForm && (
              <>
                <FormLine>
                  <GrowField label={t('booking:address.street')} name="street">
                    {(input) => (
                      <StringField
                        {...input}
                        onChange={(value) => {
                          input.onChange(value);
                          setShouldRefetch(true);
                        }}
                        type="text"
                      />
                    )}
                  </GrowField>
                  <AddressField
                    label={t('booking:address.house_number')}
                    name="house_number"
                  >
                    {(input) => (
                      <StringField
                        {...input}
                        onChange={(value) => {
                          input.onChange(value);
                          setShouldRefetch(true);
                        }}
                        type="text"
                      />
                    )}
                  </AddressField>
                  <AddressField
                    label={t('booking:address.floor_number')}
                    name="floor_number"
                  >
                    {(input) => (
                      <StringField
                        {...input}
                        onChange={(value) => {
                          input.onChange(value);
                          setShouldRefetch(true);
                        }}
                        type="text"
                      />
                    )}
                  </AddressField>
                  <AddressField
                    label={t('booking:address.unit_number')}
                    name="unit_number"
                  >
                    {(input) => (
                      <StringField
                        {...input}
                        onChange={(value) => {
                          input.onChange(value);
                          setShouldRefetch(true);
                        }}
                        type="text"
                      />
                    )}
                  </AddressField>
                </FormLine>
                <FormLine>
                  <GrowField
                    label={t('booking:address.place_name')}
                    name="place_name"
                  >
                    {(input) => (
                      <StringField
                        {...input}
                        onChange={(value) => {
                          input.onChange(value);
                          setShouldRefetch(true);
                        }}
                        type="text"
                      />
                    )}
                  </GrowField>
                  <AddressField
                    label={t('booking:address.postal_code')}
                    name="postal_code"
                  >
                    {(input) => (
                      <StringField
                        {...input}
                        onChange={(value) => {
                          input.onChange(value);
                          setShouldRefetch(true);
                        }}
                        type="text"
                      />
                    )}
                  </AddressField>
                  <AddressField label={t('booking:address.city')} name="city">
                    {(input) => (
                      <StringField
                        {...input}
                        onChange={(value) => {
                          input.onChange(value);
                          setShouldRefetch(true);
                        }}
                        type="text"
                      />
                    )}
                  </AddressField>
                  <AddressField
                    label={t('booking:address.country')}
                    name="country"
                  >
                    {(input) => (
                      <StringField
                        {...input}
                        onChange={(value) => {
                          input.onChange(value);
                          setShouldRefetch(true);
                        }}
                        type="text"
                      />
                    )}
                  </AddressField>
                </FormLine>
                <FormLine>
                  <SaveButton
                    disabled={submitting}
                    form="addressForm"
                    loading={submitting}
                    type="submit"
                  >
                    {t('translation:button.save')}
                  </SaveButton>
                </FormLine>
                <ErrorMessage>{submitError}</ErrorMessage>
              </>
            )}
          </form>
        )}
      </Form>
    </Modal>
  );
}