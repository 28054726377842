export function sortBy<T, K extends keyof T>(
  array: Array<T>,
  key: K,
): Array<T> {
  return [...array].sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }

    if (a[key] > b[key]) {
      return 1;
    }

    return 0;
  });
}
