import React from 'react';
import { IconName, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

library.add(fas);

const StyledIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
`;

interface Props {
  icon: IconName;
}

export default function Icon({ icon }: Props) {
  return (
    <StyledIcon>
      <FontAwesomeIcon icon={['fas', icon]} />
    </StyledIcon>
  );
}
